import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "bg-white border border-gray-200 rounded-lg p-6 space-y-5 shadow" }
const _hoisted_2 = { class: "flex flex-row justify-between items-center" }
const _hoisted_3 = { class: "text-lg font-semibold" }
const _hoisted_4 = { class: "flex flex-row items-center gap-4" }
const _hoisted_5 = { class: "grid gap-4 w-full grid-cols-1 md:grid-cols-2 lg:grid-cols-4" }
const _hoisted_6 = { class: "flex justify-center items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseTextInput = _resolveComponent("BaseTextInput")!
  const _component_AxialRotation = _resolveComponent("AxialRotation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["flex flex-row items-center space-x-3", { 'bg-gray-200 rounded text-transparent': _ctx.loading }])
      }, [
        _createVNode(_component_BaseIcon, {
          name: _ctx.properties.icon,
          height: "20px",
          stroke: false,
          class: _normalizeClass({ invisible: _ctx.loading })
        }, null, 8, ["name", "class"]),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.properties.label), 1),
        (_ctx.isOptional)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass([_ctx.loading ? 'text-transparent bg-gray-200 rounded-lg' : 'text-gray-500', "text-xs font-medium cursor-default capitalize mt-1"])
            }, _toDisplayString(_ctx.$t('platform.common.optional')), 3))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_BaseTextInput, {
          modelValue: _ctx.data.flat_k,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.flat_k) = $event)),
          "data-dusk": `${_ctx.laterality}-flat-k`,
          label: _ctx.$t('platform.eyes.flat-k'),
          symbol: _ctx.symbol,
          placeholder: _ctx.$t('platform.common.enter-value'),
          error: _ctx.errors.flat_k && _ctx.errors.flat_k[0],
          onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateValue({ flat_k: _ctx.data.flat_k, steep_k: _ctx.data.steep_k })))
        }, null, 8, ["modelValue", "data-dusk", "label", "symbol", "placeholder", "error"]),
        _createVNode(_component_BaseTextInput, {
          modelValue: _ctx.data.steep_k,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.data.steep_k) = $event)),
          "data-dusk": `${_ctx.laterality}-steep-k`,
          label: _ctx.$t('platform.eyes.steep-k'),
          symbol: _ctx.symbol,
          placeholder: _ctx.$t('custom.menicon.encounter.measurements.steep-k-placeholder'),
          error: _ctx.errors.steep_k && _ctx.errors.steep_k[0],
          onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateValue({ flat_k: _ctx.data.flat_k, steep_k: _ctx.data.steep_k })))
        }, null, 8, ["modelValue", "data-dusk", "label", "symbol", "placeholder", "error"]),
        _createVNode(_component_BaseTextInput, {
          modelValue: _ctx.data.flat_k_axis,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.data.flat_k_axis) = $event)),
          "data-dusk": `${_ctx.laterality}-flat-k-axis`,
          label: _ctx.$t('platform.eyes.flat-k-axis'),
          symbol: "°",
          placeholder: _ctx.$t('platform.common.enter-value'),
          error: _ctx.errors.flat_k_axis && _ctx.errors.flat_k_axis[0],
          onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.updateValue({ flat_k_axis: _ctx.data.flat_k_axis })))
        }, null, 8, ["modelValue", "data-dusk", "label", "placeholder", "error"]),
        _createVNode(_component_BaseTextInput, {
          modelValue: _ctx.data.hvid,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.data.hvid) = $event)),
          "data-dusk": `${_ctx.laterality}-hvid`,
          label: _ctx.$t('platform.eyes.hvid'),
          symbol: "mm",
          placeholder: _ctx.$t('platform.common.enter-value'),
          error: _ctx.errors.hvid && _ctx.errors.hvid[0],
          onChange: _cache[7] || (_cache[7] = ($event: any) => (_ctx.updateValue({ hvid: _ctx.data.hvid })))
        }, null, 8, ["modelValue", "data-dusk", "label", "placeholder", "error"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_AxialRotation, {
          size: 88,
          "inner-color": _ctx.axialRotationColor,
          value: _ctx.data.flat_k_axis ? parseFloat(_ctx.data.flat_k_axis) : 0
        }, null, 8, ["inner-color", "value"])
      ])
    ])
  ]))
}