
import { Vue, Options } from 'vue-class-component';
import { Laterality } from '@/custom/menicon/models';
import BaseTextInput from '@/lib/components/Input/BaseTextInput.vue';
import BaseIcon from '@/lib/components/Icon/BaseIcon.vue';
import { MeasurementsValue } from '@/lib';
import AxialRotation from '@/lib/components/AxialRotation.vue';
import { KUnit } from '@/models';
import { kUnits } from '@/constants';

@Options({
  components: { AxialRotation, BaseIcon, BaseTextInput },
  props: {
    laterality: {
      type: String,
      required: true
    },
    isOptional: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    kUnit: {
      type: String,
      default: KUnit.MM,
      validator: (value: string) => kUnits.includes(value)
    }
  }
})
export default class MeasurementCard extends Vue {
  laterality!: Laterality;
  data!: MeasurementsValue;
  kUnit!: KUnit;

  get lateralityProps(): {
    [laterality: string]: { icon: string; label: string };
    } {
    return {
      [Laterality.right]: {
        icon: 'right-eye',
        label: this.$t('platform.eyes.right-eye') as string
      },
      [Laterality.left]: {
        icon: 'left-eye',
        label: this.$t('platform.eyes.left-eye') as string
      }
    };
  }

  errors!: { [key: string]: string[] };

  get properties(): { icon: string; label: string } {
    return this.lateralityProps[this.laterality];
  }

  get axialRotationColor() {
    return this.laterality === Laterality.right ? 'primary-500' : 'success-500';
  }

  get symbol() {
    return this.kUnit === KUnit.DIOPTRES ? 'D' : 'mm';
  }

  updateValue(updates: { [key: string]: any }) {
    this.$emit('input', {
      ...this.data,
      ...updates
    });
    this.$emit('update', updates);
  }
}
